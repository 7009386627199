import { InjectionToken } from "@angular/core"

export let APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG')

/*
Note :  
please keep AppConfig properties in sync with
   /environments/config/[env]/config.js!
*/
export class AppConfig {

    pathBase : string

    apiUrl: string

    legacyAppBaseUrl : string

    legacyAppSessionManagmentUrl : string


    vendor : {
      googleMapUrl : string,

      imageRight : boolean,
      
      syncfusionLicenseKey: string
    }

    features : {
      
      activities : {
        showFormLetterLinkViaUIDInText : boolean,
      },

      fnol : FnolConfig

      uwReferrals : UwReferralConfig, 

      copart : CopartConfig       

      printChecks : PrintChecksConfig

      homePages : HomePagesConfig

    }

    ssoPortalUrl: string

    envsHeader: string
    clientHeader: string
    clientName: string
}

export class FnolConfig {
    afterSave_GenerateAccord: boolean
    afterSave_GenerateFormLetterAnchor : boolean
    afterSave_AttachRecordedCalls :boolean
    afterSave_ProcessReserves : boolean

    /* not in use */
    onSave_ShowTriage : boolean
}

export class UwReferralConfig{
  detail_requireReferralReason: boolean
  detail_ShowYnIndicators: boolean
  detail_ShowLackOfCooperation: boolean
}

export class CopartConfig {
  showConnectionTest : boolean
}

export class PrintChecksConfig {
  isCombinedChecksEnabled : boolean
  isPrintAndVerifyCombined : boolean
}


export class HomePagesConfig{
  claimDesk_shouldShowDocumentsLink : boolean
  claimDesk_shouldShowDocumentsFullOrEmpty : boolean
}




export const AppConfigFactory = () => {
  // Create env
  const config = new AppConfig();

  // Read environment variables from browser window
  const browserWindow = window || {};
  const browserWindowEnv = browserWindow['__env'] || {};

  // Assign environment variables from browser window to env
  // In the current implementation, properties from env.js overwrite defaults from the EnvService.
  // If needed, a deep merge can be performed here to merge properties instead of overwriting them.
  for (const key in browserWindowEnv) {
    if (browserWindowEnv.hasOwnProperty(key)) {
      config[key] = window['__env'][key];
    }
  }

  return config;
};

